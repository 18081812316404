import React from 'react';
import {createRoot} from 'react-dom/client';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import './index.css';
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import CacheBloc from './Bloc/CacheBloc';
import {CookiesProvider} from 'react-cookie';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <CookiesProvider>
      <App />
    </CookiesProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register({
  onUpdate: CacheBloc.onUpdate,
  onSuccess: CacheBloc.onSuccess
});
