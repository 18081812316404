import Keycloak from "keycloak-js";
import AuthBloc from "./Bloc/AuthBloc";
import PouchDbUtils from "./utils/PouchUtils";


class MyKeycloak extends Keycloak {
    hasRole = (role) => {
        if(this.authenticated && this.tokenParsed) {
            return this.tokenParsed["_couchdb.roles"]?.includes(role);
        }
        return false;
    }
    isAdmin = () => this.hasRole("_admin");
    isUser = () => this.hasRole("user");
    isSA = () => this.hasRole("sa");
}


const keycloak = new MyKeycloak();

// keycloak.init({
//     onLoad: 'check-sso',
//     silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
// }).then(authenticated => {
//     console.log("keycloak: ", keycloak);
//     try {
//         if(authenticated) {
//             // var userDbName = PouchDbUtils.usernameToDbName(keycloak.tokenParsed.sub);
//             // console.log("Prv db name: ", userDbName)
    
//             keycloak.initials = _getInitials(keycloak.tokenParsed.name)
    
//             AuthBloc.setPrvToken(keycloak.token);
//         }
//         else {
//             AuthBloc.setPrvToken(null);
//         }
        
//     } catch (error) {
//         console.log("Error after authenticating");        
//     }
// }).catch(err => {
//     console.log("Error authenticating: ", err)
// })

export default keycloak;

const _getInitials = (string) => {
    try {
        
        var names = string.split(' '), initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    } catch (error) {
        console.log("Error getting initials", error);   
    }
    return string;
};