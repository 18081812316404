//import { useKeycloak } from "@react-keycloak/web";

const PrivateRoute = ({ children }) => {
 //const { keycloak } = useKeycloak();

 const isLoggedIn = false;//keycloak.authenticated;

 return isLoggedIn ? children : "Unauthorized";// window.location = "/";
};

export default PrivateRoute;