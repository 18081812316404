import React, { useEffect, useState } from 'react';
import CalBloc from '../Bloc/CalBloc'
import CalSwitch from './CalSwitch';
import Grid from '@mui/material/Grid';
import DateInfo from './DateInfo';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
//import MonthSelector from './MonthSelector';
//import YearSelector from './YearSelector';
import { Button } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


const CalController = () => {
    const [day, setDay] = useState();

    const monthSelected = (e) => {
        console.log("Month selected: ", e);
        CalBloc.setMonth(e-1);
    }
    const yearSelected = (e) => {
        console.log("Year selected: ", e);
        CalBloc.setYear(e);
    }

    useEffect(() => {
        const subDay = CalBloc.subDay().subscribe((v) => {
            setDay(v);
        })

        return () => {
            subDay.unsubscribe();
        }

    }, []);

    return (
        <Box sx={{ width: '100%', }}>
            <Stack spacing={.1}>
                <Item sx={{padding: '0'}}>
                    <Grid container className="cal-info">
                        <Grid item xs={4} style={{textAlign:"left"}}>
                            <CalSwitch />
                        </Grid>
                        <Grid item xs={8}   style={{textAlign:"right"}}>
                            {/* <MonthSelector 
                                selectedValue={CalBloc.getMonth()+1} 
                                onChange={(e) => monthSelected(e)}  min={1} max={CalBloc.getCalET() ? 13 : 12} />
                            <YearSelector 
                                selectedValue={CalBloc.getYear()} 
                                onChange={(e) => yearSelected(e)}  min={1900} max={2099} /> */}
                            {/* <DateSearch /> */}
                        </Grid>
                    </Grid>
                </Item>
                <Item>
                    <Grid container className="cal-info">
                        <Grid item xs={12}>
                            <DateInfo day={day} />
                        </Grid>
                    </Grid>
                </Item>
                <Item sx={{padding: '0'}}>
                    <Grid container className="cal-nav">
                        <Grid item xs={5} className="left">
                            <Button  onClick={() => CalBloc.addToYear(-1)}>
                                <KeyboardDoubleArrowLeftIcon />
                            </Button>
                            <Button  onClick={() => CalBloc.addToMonth(-1)}>
                                <ChevronLeftIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={2} className="ymSel">
                            {!CalBloc.isCurrentMonth()
                                && <Button onClick={() => CalBloc.resetCal()} >
                                        <RotateLeftIcon 
                                            sx={{
                                                fontSize: '1.2rem', 
                                                marginTop: '.25rem'
                                            }}   />
                                    </Button>
                                
                            }
                        </Grid>
                        <Grid item xs={5} className="right">
                            <Button onClick={() => CalBloc.addToMonth(1)}>
                                <ChevronRightIcon />
                            </Button>

                            <Button  onClick={() => CalBloc.addToYear(1)}>
                                <KeyboardDoubleArrowRightIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Item>
            </Stack>
        </Box>
    );

}
export default CalController;