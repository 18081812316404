import React, { useEffect, useState } from 'react';
import CalEventBloc from "../Bloc/CalEventBloc";
//import { useKeycloak } from "@react-keycloak/web";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CalBloc from '../Bloc/CalBloc';
import * as CalHelper from '../utils/CalHelper'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'left',
    width: '100%',
    marginBottom: '1px',
    borderRadius: 3,
    color: theme.palette.text.secondary,
}));

const CalEvents = () => {
    //const { keycloak } = useKeycloak();

    const handleSelection = (p) => {
        // if (keycloak.authenticated && (p.isPrivate || keycloak.isSA())) {
        //     CalEventBloc.setSelCalEvent(p)
        // }
    }

    const phEvents = CalEventBloc.getCurMonth();
    const selDate = CalBloc.getSelDate();

    if (phEvents) {
        const all = phEvents.map((p, i) => {
            const curDate = new Date(p.day);
            const className = "cal-event" + (CalHelper.isSameDate(selDate, curDate) ? " sel" : "");
            return (
                <ListItem disablePadding disableGutters className={className}
                    key={i} onClick={() => handleSelection(p)}>
                    <Item>
                        <ListItemButton disableGutters sx={{ padding: '0 .5rem', margin: '0' }}>
                            <ListItemText>
                                <span className='event-text'>
                                    <span className='day-part'>{p.d}</span> {p.title} 
                                    <span className='event-type'>{p.isPrivate && "(private)"}</span>
                                </span>
                            </ListItemText>
                        </ListItemButton>
                    </Item>
                </ListItem>
            )
        });

        return <Box>
            <List sx={{ padding: '0.25rem 0' }}>
                {all}
            </List>
        </Box>
    }
    return null;
}
export default CalEvents;